import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import manifest from '../data/manifest';

import './HTMLPart.scss';

interface ContainerProps {
    routePath: string,
    hash: string,
    onUpdate: any,
    handleLinkClick: any
}

interface ContainerState {
    routePath: string,
    html: string,
    isLoading: boolean
}

class HTMLPart extends React.Component<ContainerProps, ContainerState> {
    constructor(props: ContainerProps) {
        super(props);

        this.state = {
            routePath: this.props.routePath,
            html: '',
            isLoading: true,
        }
    }

    fetchOEBPart = (fileName: string) => {
        console.log(`Fetching ${fileName}`);

        if (this.state.routePath === fileName && this.state.html) {
            console.log('skipping fetch...already loaded');

            return;
        }

        this.setState({isLoading:true})
        fetch(`/docparts/${fileName}`)
            .then(response => response.text())
            .then(text => {
                this.setState({html:text, routePath: fileName, isLoading:false});

                this.props.onUpdate();
            });
    }

    getSectionNumber = (part: string) => {
        const matches = part.match(/(?<partNumber>\d+)/);

        if (! matches) {
            return 1;
        }

        // @ts-ignore
        return parseInt(matches.groups.partNumber);
    }

    getMappedFileName = (fileName: string) => {

        // @ts-ignore
        const mappedFilename = manifest[fileName].mappedFile;

        console.log(`Mapped to ${mappedFilename}`);

        return mappedFilename;
    }

    componentDidMount() {
        this.fetchOEBPart(this.getMappedFileName(this.props.routePath))
    }

    componentDidUpdate(prevProps: Readonly<ContainerProps>, prevState: Readonly<ContainerState>, snapshot?: any) {
        if (this.state.isLoading) {
            return;
        }

        const mappedFilename = this.getMappedFileName(this.props.routePath);

        if (mappedFilename !== this.state.routePath) {
            this.fetchOEBPart(mappedFilename);
        } else if (this.props.hash !== prevProps.hash) {
            console.log('Firing onUpdate callback', {props:this.props,state:this.state,prevProps:prevProps, prevState:prevState})
            this.props.onUpdate(this.props, prevProps);
        }
    }

    render() {
        return (
            <>
                {this.state.isLoading ?
                    <div className="loading-wrapper">
                        <CircularProgress/>
                    </div>
                    :
                    <div key={this.state.routePath} className="html-part" data-section={this.getSectionNumber(this.state.routePath)} dangerouslySetInnerHTML={{__html: this.state.html}} onClick={this.props.handleLinkClick}/>
                }
            </>
        );
    }
}

export default HTMLPart;
