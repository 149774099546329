import React from 'react';
import {BrowserRouter, Navigate, Route, Routes,} from 'react-router-dom';
import Menu from './components/Menu';
import Bible from './pages/Bible';
import Search from "./pages/Search";
import Memory from "./components/Memory";

const App: React.FC = () => (
    <div className={'app-wrap'}>
        <BrowserRouter>
            <Memory />
            <Menu />
            <Routes>
                <Route path="/home" element={ <Navigate to="/part0004.html#15141" replace={true} /> } />
                <Route path="/search" element={<Search/>}/>
                <Route path="/:file" element={<Bible/>} />
                <Route path="/" element={<Navigate to="/home" replace={true} />}/>
            </Routes>
        </BrowserRouter>
    </div>
);

export default App;
