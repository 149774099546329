import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Plugins
    // , AppState
} from '@capacitor/core';

const { Storage, App } = Plugins;

const Memory: React.FC = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    function goTo(path:any) {
        navigate(path, { replace: true });
    }

    // App.addListener( 'appStateChange', (state: AppState) => {
    //     if ( ! state.isActive ) {
    //         onPause()
    //     } else {
    //         onResume();
    //     }
    // });

    async function onResume() {
        let lastPage = await Storage.get({ key:'lastPage'});
console.log({lastPage:lastPage})
        if (lastPage.value) {
            goTo(lastPage.value);
        }
    }

    async function onPause() {
        let currentPage = location.pathname + location.hash;

        // @TODO get the position using intersection instead of route to account for user scrolling without being routed.
        await Storage.set({ key:'lastPage', value: currentPage});
    }

    return null;
};

export default Memory;
