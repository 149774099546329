import React from 'react';
import { useNavigate} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import {IconButton, Toolbar} from '@mui/material';
import {Home, MoreVert, Search} from '@mui/icons-material';
import PersistentDrawerRight from "./SearchDrawer";

import './Menu.css';

interface AppPage {
    url: string;
    label: string;
    className: string;
}

interface MenuPropsInterface {
    children: any
}

const Menu: React.FC = (props) => {
    // const useStyles = {
    //     text: {
    //         padding: theme.spacing(2, 2, 0),
    //     },
    //     paper: {
    //         paddingBottom: 50,
    //     },
    //     list: {
    //         marginBottom: theme.spacing(2),
    //     },
    //     subheader: {
    //         backgroundColor: theme.palette.background.paper,
    //     },
    //     appBar: {
    //         top: 'auto',
    //         bottom: 0,
    //     },
    //     grow: {
    //         flexGrow: 1,
    //     },
    //     fabButton: {
    //         position: 'absolute',
    //         zIndex: 1,
    //         top: -30,
    //         left: 0,
    //         right: 0,
    //         margin: '0 auto',
    //     },
    // };

    const [open, setOpen] = React.useState(false);

    const toggleSearchDrawer = (isOpen: boolean) => {
        setOpen(isOpen);

        if (isOpen) {
            // @ts-ignore
            document.querySelector('.ais-SearchBox-input').focus()
        }
    };

    const classes = {
        appBar: '',
        grow: ''
    };
    const navigate = useNavigate();

    function HideOnScroll(props: MenuPropsInterface) {
        return (
            <Slide appear={false} direction="up" in={!useScrollTrigger()} style={{ top: 'auto', bottom: 0 }}>
                {props.children}
            </Slide>
        );
    }

    function goTo(path:any) {
        navigate(path, { replace: true });
    }

    // @ts-ignore
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar position="fixed" color="primary" className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => goTo('/home')}>
                            <Home/>
                        </IconButton>

                        <div style={ { flexGrow: 1 }} />

                        <IconButton edge="end" color="inherit" onClick={() => toggleSearchDrawer(true)}>
                            <Search />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <PersistentDrawerRight open={open} toggleSearchDrawer={toggleSearchDrawer}/>
        </>
    );
};

export default Menu;
