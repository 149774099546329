import React from 'react';

import SearchPanel from "../components/SearchPanel";

const Search = () => (
    <section className="calibre">
        <SearchPanel onClick={() => {return false;}}/>
    </section>
);

export default Search;
