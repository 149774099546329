import React from 'react';
import { InstantSearch, SearchBox, Hits, Highlight, Pagination, SortBy, connectStateResults, connectPagination, Stats } from 'react-instantsearch-dom';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import {Link} from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import './SearchPanel.css';

interface HitProps {
    hit: any
}

interface SearchPanelProps {
    onClick: any
}

const meili_url = 'http://ec2-18-217-44-255.us-east-2.compute.amazonaws.com';
const meili_key = 'YTQ1MGQxOWI0NGZmOTI4OTk2NzExODYx';
const searchClient = instantMeiliSearch(
    `https://proxy.timj.us/${meili_url}`,
    meili_key,
    {
        placeholderSearch: false,
        paginationTotalHits: 400 // 20 pages.
    }
);


let onClick: any = null;

const SearchPanel = (props:SearchPanelProps) => {
    let [searchIndex, setSearchIndex] = React.useState('bible');

    onClick = props.onClick;

    const ResultsStats = () => (
        <div className="pagination-stats">
            <Stats
                translations={{
                    stats(nbHits, timeSpentMS) {
                        return `${nbHits} ${searchIndex === 'bible' ? 'verses' : 'footnotes'}`;
                    },
                }}
            />
        </div>
    );

    const Results = connectStateResults(
        ({ searchState, searchResults, allSearchResults, error }) => {

            return (
                error
                    ? <p>There was a problem fetching search results.</p>
                    : (
                        searchResults && searchResults.nbHits !== 0
                            ? ( <>{searchState.query ? <ResultsStats/> : ''}</>)
                            : ( <p>No results for &ldquo;{searchState.query}&rdquo;.</p> )
                    )
            )
        }

    );

    const ResultsPagination = connectPagination(({ currentRefinement, nbPages }) =>
            nbPages && nbPages > 1
                ? <Pagination showLast />
                : <></>
    );

    return (
        <InstantSearch
            indexName={searchIndex}
            key={searchIndex}
            searchClient={searchClient}
        >
            <SearchBox autoFocus={false} />
            <SortBy
                defaultRefinement={searchIndex}
                items={[
                    { value: `${searchIndex}:order:asc`, label: 'Order' },
                    {
                        value: searchIndex,
                        label: 'Relevance',
                    },
                ]}
            />

            <RadioGroup row className="search-index-toggle" aria-label="position" name="position" defaultValue="bible" onChange={v => setSearchIndex(v.target.value)} value={searchIndex}>
                <FormControlLabel
                    value="bible"
                    control={<Radio color="primary" />}
                    label="Verses"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="footnotes"
                    control={<Radio color="primary" />}
                    label="Footnotes"
                    labelPlacement="end"
                />
            </RadioGroup>

            <Results/>

            <Hits hitComponent={ searchIndex === 'bible' ? VerseHit : FootnoteHit } />

            <div className="pagination">
                <ResultsPagination/>
            </div>
        </InstantSearch>
    );
};

function VerseHit(props:HitProps) {
    return (
        <div key={props.hit.id}>
            <p className="verse" key={props.hit.id}>
                <b className={"calibre6"}>
                    <Link to={`/${props.hit.part}#${props.hit.id}`} className={"calibre9"} onClick={ () => onClick() }>
                        <Highlight attribute="book" hit={props.hit} />
                        &nbsp;<Highlight attribute="chapter" hit={props.hit} />
                        :<Highlight attribute="verse" hit={props.hit} />
                    </Link>
                </b>

                &nbsp;<Highlight attribute="text" hit={props.hit} />
            </p>
        </div>
    );
}

function FootnoteHit(props:HitProps) {
    let id = [...props.hit.id.split('__')].pop();

    return (
        <div key={id}>
            <p className="note-head">
                <b className={"calibre6"}>
                    <Link to={`/${props.hit.part}#${id}`} className={"calibre9"} onClick={ () => onClick() }>
                        <Highlight attribute="book" hit={props.hit} />
                        &nbsp;<Highlight attribute="chapter" hit={props.hit} />
                        :<Highlight attribute="verse" hit={props.hit} />
                        <sup className={"calibre22"}>{props.hit.note}</sup>
                    </Link>
                </b>
            </p>

            <p className="note" key={props.hit.id}>
                &nbsp;<Highlight attribute="text" hit={props.hit} />
            </p>
        </div>
    );
}

export default SearchPanel;
