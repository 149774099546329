import React, { useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import BibleContainer from '../components/BibleContainer';
import './Bible.css';
import manifest from '../data/manifest';
import FootnoteContainer from "../components/FootnoteContainer";
import {useParams} from "react-router";

const Bible = () => {
    const [footNotePath, setFootNotePath] = useState('');
    const [footNoteHash, setFootNoteHash] = useState('');
    const [footNoteVisible, setFootNoteVisible] = useState(false);
    const navigate = useNavigate();
    const routeParams = useParams();

    const getSectionNumber = (part: string) => {
        const matches = part.match(/(?<partNumber>\d+)/);

        if (! matches) {
            return 1;
        }

        // @ts-ignore
        return parseInt(matches.groups.partNumber);
    }

    const isFootNotePath = (path:string) => getSectionNumber(path) > 1256;

    // @ts-ignore
    const maybeUpdateRoute = (e) => {
        e.preventDefault();

        let link = null;

        if ( e.target.href ) {
            link = e.target;
        } else if ( e.target.parentElement.href ) {
            link = e.target.parentElement;
        }

        if (! link) {
            return;
        }
console.log('Bible: link clicked');
        console.log(e.target, link);
        // @TODO be better about building the target.
        const newSectionTarget = link.href.replace(window.location.origin,'');

        if ( isFootNotePath(newSectionTarget)) {
            console.log('Is footnote')
            const targetParts = newSectionTarget.trim('/').split('#');
            const path = targetParts[0].replace(/^\//g,'');;
            const hash = targetParts[1] || '';

            setFootNotePath(path);
            setFootNoteHash(hash);
            setFootNoteVisible(true);
        } else {
            console.log('Target compare: ', newSectionTarget,routeParams.file, routeParams)
            navigate(newSectionTarget, { replace: true })
        }
    }

    const location = useLocation();

    // @ts-ignore
    const mapping = manifest[routeParams.file];

    const mappedPath = mapping.mappedFile;

    document.title = mapping.title;

    return (
        <>
            <BibleContainer file={mappedPath} hash={location.hash} handleLinkClick={maybeUpdateRoute} footnotePanelOpen={footNoteVisible}/>

            {footNotePath &&
                <FootnoteContainer isActive={footNoteVisible} file={footNotePath} hash={footNoteHash} onCloseModal={() => setFootNoteVisible(false)} handleLinkClick={(e: any) => maybeUpdateRoute(e)}/>
            }
        </>
    );
};

export default Bible;
