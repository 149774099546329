import React from 'react';

import './FootnoteContainer.scss';
import HTMLPart from "./HTMLPart";
import {Drawer} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface ContainerProps {
    isActive: boolean,
    file: string,
    hash: string,
    onCloseModal: any,
    handleLinkClick: any
}

interface ContainerState {
    isActive: boolean
}

interface OnUpdateProps {
    routePath: string,
    hash: string
}

class FootnoteContainer extends React.Component<ContainerProps, ContainerState> {
    constructor(props: ContainerProps) {
        super(props);

        this.state = {
            isActive: this.props.isActive
        }
    }

    jumpToId = () => {
        console.log('JUMPING TO ID')

        const hash = this.props.hash;

        const elementId = hash.replace('#', '');

        if ( ! elementId ) {
            return;
        }

        const target = document.getElementById(elementId);

        if (target) {
            target.scrollIntoView();
        } else {
            console.log('Link target could not be found.');
        }
    }

    componentDidMount() {
        console.log(this.props.file)
    }

    componentDidUpdate(prevProps: Readonly<ContainerProps>, prevState: Readonly<ContainerState>, snapshot?: any) {
        console.log('updated...', this.props.file)
        // Handle new footnote link click when the footnote modal is already open.
        if (this.props.isActive && prevProps.hash && this.props.hash !== prevProps.hash) {
            this.jumpToId();
        }
    }

    render() {
        return (
            <Drawer anchor={'bottom'} variant="persistent" className='footnotes-wrapper' open={this.props.isActive} >
                <span className={'footnote-panel-close'}>
                    <IconButton color="inherit" onClick={() => this.props.onCloseModal()}>
                        <CloseIcon/>
                    </IconButton>
                </span>
                <HTMLPart routePath={this.props.file} hash={this.props.hash} onUpdate={() => setTimeout(this.jumpToId)} handleLinkClick={this.props.handleLinkClick}/>
            </Drawer>
        );
    }
}

export default FootnoteContainer;
