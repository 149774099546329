import React from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchPanel from "./SearchPanel";

import './SearchDrawer.css';

interface DrawerProps {
    open: boolean,
    toggleSearchDrawer: any
}

interface DrawerState {
    open: boolean
}

export default class PersistentDrawerRight extends React.Component<DrawerProps, DrawerState> {
    constructor(props: DrawerProps) {
        super(props);

        this.state = {
            open: this.props.open
        }
    }

    componentDidUpdate(prevProps: Readonly<DrawerProps>, prevState: Readonly<DrawerState>, snapshot?: any) {
        if (this.props.open !== prevState.open ) {
            this.setState({open:this.props.open});
        }
    }

    render() {
        return (
            <Drawer
                className={'bible-search-drawer'}
                variant="persistent"
                anchor="right"
                open={this.state.open}
            >
                <div className={'classes.drawerHeader'}>
                    <IconButton onClick={() => this.props.toggleSearchDrawer(false)}>
                        <ChevronRightIcon/>
                    </IconButton>
                </div>

                <Divider/>

                <div className="search-panel">
                    <SearchPanel onClick={this.props.toggleSearchDrawer}/>
                </div>
            </Drawer>
        );
    }
}
