import React from 'react';
import './BibleContainer.css';

import HTMLPart from "./HTMLPart";

interface ContainerProps {
    file: string,
    hash: string,
    handleLinkClick: any,
    footnotePanelOpen: boolean
}

interface OnUpdateProps {
    routePath: string,
    hash: string
}

class BibleContainer extends React.Component<ContainerProps> {

    jumpToId = () => {
        const elementId = this.props.hash.replace('#', '');

        console.log(`BibleContainer: JUMPING TO ID "${elementId}"`);

        if ( ! elementId ) {
            return;
        }

        const target = document.getElementById(elementId);

        if (target) {
            console.log('Scrolling to ', elementId, target)

            target.scrollIntoView();
        } else {
           console.error(`Target "${elementId}" could not be found.`);
        }
    }

    render() {
        return (
            <div id="scrollableDiv" className={this.props.footnotePanelOpen? 'bible-container footnotes-visible' : 'bible-container'}>
                <HTMLPart routePath={this.props.file} hash={this.props.hash} onUpdate={() => setTimeout(this.jumpToId)} handleLinkClick={this.props.handleLinkClick}/>
            </div>
        );
    }
}

export default BibleContainer;
